import React from "react"
import Layout from "../components/layout"
import './index.css'
import { Container, Row, Col, Navbar, Nav, NavItem, NavbarBrand, NavLink, Button, InputGroup, InputGroupText, InputGroupAddon, ModalFooter, Form, FormGroup, Label, Input, Alert, FormText } from 'reactstrap'
import { signIn, getTeams, updateTeam, getImageURL, uploadImage, sendResetEmail } from '../services/team'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import md5 from "md5"
import uuidv1 from 'uuid'

class ManagePage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            tab: 'teams',
            token: null,
            email: '',
            password: '',
            signInError: '',
            open: false,
            loadingTeams: false,
            savingTeam: false,
            teams: [],
            selectedTeamId: -1,
            unsavedChanges: false,
            showSignOutButton: false,
            logoEditInput: '',
            signingIn: false,
            forgotPassword: false,
            resetEmail: '',
            forgotEmailSent: false,
            forgotEmailSending: false
        };


    }


    async logOut() {
        localStorage.removeItem('token');
        this.setState({token: null});
        navigate("/manage")
    }

    setTab(tab) {
        this.setState({ tab });
    }

    async loadTeams() {
        this.setState({ loadingTeams: true });
        var teams = await getTeams(localStorage.getItem("token"));
        if (teams.error === "") {
            this.setState({ teams: teams.teams, loadingTeams: false });
        }
        else {
            await this.logOut();
        }

    }

    async componentDidMount() {

        if (localStorage.getItem("token")) {
            this.setState({token: localStorage.getItem("token")});
            await this.loadTeams();
        }
    }


    async componentDidUpdate() {

        if (this.state.token == null && localStorage.getItem("token")) {
            this.setState({token: localStorage.getItem("token")});
            await this.loadTeams();
        }
    }
    

    selectTeam(selectedTeamId) {
        this.setState({ selectedTeamId })
    }

    updateTeam(field, value) {
        let teams = this.state.teams;
        teams[this.state.selectedTeamId][field] = value;
        this.setState({ teams, unsavedChanges: true })
    }

    updateImage() {
        this.setState({ unsavedChanges: true })
    }

    async sendResetEmail() {
        this.setState({ forgotEmailSent: false, forgotEmailSending: true });
        await sendResetEmail(this.state.resetEmail);
        this.setState({ forgotEmailSent: true, forgotEmailSending: false });
    }

    addNewTeam() {
        let teams = this.state.teams;

        let newTeam = {
            Name: "New Team",
            Id: null,
            Address: "",
            Age: "",
            City: "",
            State: "",
            Zip: "",
            Coach: "",
            Description: "",
            ContactEmail: "",
            Icon: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            Validated: false,
            Latitude: null,
            Longitude: null
        }


        teams.push(newTeam);
        this.setState({ teams, selectedTeamId: teams.length - 1, unsavedChanges: true });

    }

    async saveTeam() {
        this.setState({ savingTeam: true });
        const token = localStorage.getItem("token");

        var fileInput = document.getElementById("imageUploadInput");
        if (fileInput.files.length > 0) {
            var file = fileInput.files[0];
            var uuid = uuidv1();
            var url = await getImageURL(token, file.type, uuid);

            if (url.error === "" && url.url !== "") {
                var uploadResponse = await uploadImage(url.url, file);
                if (uploadResponse["error"] === "") {
                    let teams = this.state.teams;
                    this.state.teams[this.state.selectedTeamId].Icon = "https://s3.amazonaws.com/logos.fastpitchhub.com/" + md5(uuid).toUpperCase();
                    this.setState({ teams });
                }

            }
        }
        // set default image
        else if (this.state.teams[this.state.selectedTeamId].Icon === '') {
            let teams = this.state.teams;
            this.state.teams[this.state.selectedTeamId].Icon = "https://s3.amazonaws.com/logos.fastpitchhub.com/generic_logo.jpg"
            this.setState({ teams });
        }

        const response = await updateTeam(token, this.state.teams[this.state.selectedTeamId]);

        if (response["error"] === "") {
            this.setState({ selectedTeamId: -1, unsavedChanges: false });
            await this.loadTeams(token);
        }
        else {            
            this.logOut();
        }

        this.setState({ savingTeam: false });
    }

    render() {

        const editTeamList = this.state.teams.map((team, index) => {
            return <option value={index} key={index}>{team.Name} {team.Age ? `(${team.Age})` : ''}</option>
        })

        var { token } = this.state;

        return (

            <Layout>

                <div className="wrapper">

                    {token === null &&

                        <Container>

                            <Row style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "50%", borderTop: "4px solid #343a40" }} noGutters>
                                <Col>
                                    You aren't logged in.  Log in by clicking 'Manage Teams' above.
                                </Col>
                            </Row>
                        </Container>

                    }


                    {token !== null &&

                        <Container>



                            <Navbar color="dark" dark>
                                <NavbarBrand className="mr-auto" style={{ color: "white" }}>Manage</NavbarBrand>
                            </Navbar>


                            <Row style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "25px", minHeight: "250px" }} noGutters>
                                <Col>



                                    <Nav pills>
                                        <NavItem>
                                            {this.state.tab == 'teams' && <NavLink style={{ cursor: "pointer" }} active onClick={() => { this.setTab('teams') }}>Teams</NavLink>}
                                            {this.state.tab != 'teams' && <NavLink style={{ cursor: "pointer" }} onClick={() => { this.setTab('teams') }}>Teams</NavLink>}
                                        </NavItem>
                                    </Nav>



                                    {editTeamList && this.state.tab == 'teams' &&
                                        <div>
                                            <hr />

                                            <div>
                                                <div style={{ marginTop: "25px" }}>Select a team to edit or create a new team.  You should create a team for each age group in your organization so they will all show up on the map.  Once you save your changes, your team will be reviewed and added to our Softball Map.</div>
                                                <div style={{ margin: "25px 0px" }}>Return to the <a href="/softball-map">Softball Map</a></div>
                                                {this.state.loadingTeams && <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin /> Loading Teams...</Alert>}

                                                <Form>
                                                    <FormGroup row>
                                                        <Col xs={7} sm={8}>
                                                            <Input type="select" onChange={(e) => this.selectTeam(e.target.value)} value={this.state.selectedTeamId}>
                                                                <option value={-1}>Choose A Team To Edit</option>
                                                                {editTeamList}
                                                            </Input>
                                                        </Col>
                                                        <Col xs={5} sm={4}><Button color="success" type="button" onClick={() => this.addNewTeam()}>Add New Team</Button></Col>
                                                    </FormGroup>

                                                </Form>
                                            </div>


                                            {this.state.selectedTeamId >= 0 &&
                                                <div style={{ backgroundColor: "#eee", border: "1px solid #ccc", padding: "10px" }}>
                                                    <h5>Edit Team/Organization Details</h5>

                                                    <Form>
                                                        <FormGroup row>
                                                            <Label sm={2}>Team Name</Label>
                                                            <Col sm={10}>
                                                                <Input type="text" value={this.state.teams[this.state.selectedTeamId].Name} onChange={(e) => this.updateTeam('Name', e.target.value)} />
                                                                <FormText color="muted">
                                                                    Just your team's name.  Leave off your age group, that comes later.
                                                            </FormText>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>
                                                                Team Logo
                                                            <br />
                                                                {this.state.teams[this.state.selectedTeamId].Icon !== '' && <img src={this.state.teams[this.state.selectedTeamId].Icon} alt={this.state.teams[this.state.selectedTeamId].Name} style={{ width: "75px", height: "auto", borderRadius: "50px" }} />}
                                                                {this.state.teams[this.state.selectedTeamId].Icon === '' && <img src="https://s3.amazonaws.com/logos.fastpitchhub.com/generic_logo.jpg" alt={this.state.teams[this.state.selectedTeamId].Name} style={{ width: "75px", height: "auto", borderRadius: "50px" }} />}
                                                            </Label>
                                                            <Col sm={10}>
                                                                <Input type="file" id="imageUploadInput" onClick={() => this.updateImage()} />
                                                                <FormText color="muted">
                                                                    This is the logo that will be displayed on the softball map.  Pick a good one.
                                                            </FormText>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Team Details</Label>
                                                            <Col sm={10}>
                                                                <Input type="textarea" value={this.state.teams[this.state.selectedTeamId].Description} onChange={(e) => this.updateTeam('Description', e.target.value)} />
                                                                <FormText color="muted">
                                                                    What is your team about?  What positions do you need?  When will you be having tryouts?  Anything relevant to your team goes here.
                                                            </FormText>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Address</Label>
                                                            <Col sm={10}>
                                                                <Input type="text" value={this.state.teams[this.state.selectedTeamId].Address} onChange={(e) => this.updateTeam('Address', e.target.value)} />
                                                                <FormText color="muted">
                                                                    This is the address where your team's marker will show up on the map.  Make sure city and state are correct too.
                                                            </FormText>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>City</Label>
                                                            <Col sm={10}>
                                                                <Input type="text" value={this.state.teams[this.state.selectedTeamId].City} onChange={(e) => this.updateTeam('City', e.target.value)} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>State</Label>
                                                            <Col sm={10}>
                                                                <Input type="select" value={this.state.teams[this.state.selectedTeamId].State} onChange={(e) => this.updateTeam('State', e.target.value)}>
                                                                    <option value="">Select Your State</option>
                                                                    <option value="AL">Alabama</option>
                                                                    <option value="AK">Alaska</option>
                                                                    <option value="AR">Arkansas</option>
                                                                    <option value="AZ">Arizona</option>
                                                                    <option value="CA">California</option>
                                                                    <option value="CO">Colorado</option>
                                                                    <option value="CT">Connecticut</option>
                                                                    <option value="DE">Delaware</option>
                                                                    <option value="DC">District of Columbia</option>
                                                                    <option value="FL">Florida</option>
                                                                    <option value="GA">Georgia</option>
                                                                    <option value="HI">Hawaii</option>
                                                                    <option value="ID">Idaho</option>
                                                                    <option value="IL">Illinois</option>
                                                                    <option value="IN">Indiana</option>
                                                                    <option value="IA">Iowa</option>
                                                                    <option value="KS">Kansas</option>
                                                                    <option value="KY">Kentucky</option>
                                                                    <option value="LA">Louisiana</option>
                                                                    <option value="ME">Maine</option>
                                                                    <option value="MD">Maryland</option>
                                                                    <option value="MA">Massachusetts</option>
                                                                    <option value="MI">Michigan</option>
                                                                    <option value="MN">Minnesota</option>
                                                                    <option value="MS">Mississippi</option>
                                                                    <option value="MO">Missouri</option>
                                                                    <option value="MT">Montana</option>
                                                                    <option value="NE">Nebraska</option>
                                                                    <option value="NV">Nevada</option>
                                                                    <option value="NH">New Hampshire</option>
                                                                    <option value="NJ">New Jersey</option>
                                                                    <option value="NM">New Mexico</option>
                                                                    <option value="NY">New York</option>
                                                                    <option value="NC">North Carolina</option>
                                                                    <option value="ND">North Dakota</option>
                                                                    <option value="OH">Ohio</option>
                                                                    <option value="OK">Oklahoma</option>
                                                                    <option value="OR">Oregon</option>
                                                                    <option value="PA">Pennsylvania</option>
                                                                    <option value="RI">Rhode Island</option>
                                                                    <option value="SC">South Carolina</option>
                                                                    <option value="SD">South Dakota</option>
                                                                    <option value="TN">Tennessee</option>
                                                                    <option value="TX">Texas</option>
                                                                    <option value="UT">Utah</option>
                                                                    <option value="VT">Vermont</option>
                                                                    <option value="VA">Virginia</option>
                                                                    <option value="WA">Washington</option>
                                                                    <option value="WV">West Virginia</option>
                                                                    <option value="WI">Wisconsin</option>
                                                                    <option value="WY">Wyoming</option>



                                                                </Input>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Head Coach</Label>
                                                            <Col sm={10}>
                                                                <Input type="text" value={this.state.teams[this.state.selectedTeamId].Coach} onChange={(e) => this.updateTeam('Coach', e.target.value)} />
                                                                <FormText color="muted">
                                                                    Who's the guy or girl in charge?
                                            </FormText>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup row>
                                                            <Label sm={2}>Age Group</Label>
                                                            <Col sm={10}>
                                                                <Input type="select" value={this.state.teams[this.state.selectedTeamId].Age} onChange={(e) => this.updateTeam('Age', e.target.value)} >
                                                                    <option value="">Choose Age Group</option>
                                                                    <option value="6U">6U</option>
                                                                    <option value="7U">7U</option>
                                                                    <option value="8U">8U</option>
                                                                    <option value="9U">9U</option>
                                                                    <option value="10U">10U</option>
                                                                    <option value="11U">11U</option>
                                                                    <option value="12U">12U</option>
                                                                    <option value="13U">13U</option>
                                                                    <option value="14U">14U</option>
                                                                    <option value="15U">15U</option>
                                                                    <option value="16U">16U</option>
                                                                    <option value="18U/HSU">18U/HSU</option>
                                                                </Input>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Contact Email</Label>
                                                            <Col sm={10}>
                                                                <Input type="text" value={this.state.teams[this.state.selectedTeamId].ContactEmail} onChange={(e) => this.updateTeam('ContactEmail', e.target.value)} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Facebook Page</Label>
                                                            <Col sm={10}>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>http://www.facebook.com/</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input type="text" value={this.state.teams[this.state.selectedTeamId].Facebook} onChange={(e) => this.updateTeam('Facebook', e.target.value)} />
                                                                </InputGroup>

                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Instragram Page</Label>
                                                            <Col sm={10}>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>http://www.instagram.com/</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input type="text" value={this.state.teams[this.state.selectedTeamId].Instagram} onChange={(e) => this.updateTeam('Instagram', e.target.value)} />
                                                                </InputGroup>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Twitter Page</Label>
                                                            <Col sm={10}>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>http://www.twitter.com/</InputGroupText>
                                                                    </InputGroupAddon>

                                                                    <Input type="text" value={this.state.teams[this.state.selectedTeamId].Twitter} onChange={(e) => this.updateTeam('Twitter', e.target.value)} />
                                                                </InputGroup>

                                                            </Col>
                                                        </FormGroup>



                                                    </Form>

                                                    {!this.state.teams[this.state.selectedTeamId].Validated && !this.state.unsavedChanges &&
                                                        <Alert color="primary">Team Pending Approval, Your Team Will Be Displayed On The Map Once Approved.</Alert>
                                                    }

                                                    {this.state.unsavedChanges &&
                                                        <div>
                                                            <Alert color="danger">You Have Unsaved Changes, Click 'Save Team' Below To Avoid Losing Changes.</Alert>
                                                            <Button color="success" onClick={() => { this.saveTeam() }}>Save Team</Button>
                                                            {this.state.savingTeam &&
                                                                <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: "10px" }} />
                                                            }
                                                        </div>
                                                    }

                                                    {!this.state.unsavedChanges &&
                                                        <div>
                                                            <Button disabled color="success" onClick={() => { this.saveTeam() }}>Save Team</Button>
                                                        </div>
                                                    }




                                                </div>
                                            }
                                        </div>
                                    }


                                </Col>
                            </Row>




                        </Container>

                    }

                </div>




            </Layout>


        )
    }
}

export default ManagePage

